<template>
    <div class="card_five" v-if="info">
        <img :src="info.logo ? info.logo : require('../../../../assets/images/card1_user.png')" alt="">
        <div class="card_con">
            <div class="companyname d-flex">
                <div class="name">{{info.companyName}}</div>
            </div>
            <div class="name_job">
                <div class="name">{{info.name}}</div>
                <div class="jposi">{{info.position || '职位'}}</div>
            </div>
            <div class="thumb">
                <img :src="info.companyLogo ? info.companyLogo : require('../../../../assets/images/card1_user.png')" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        info: Object
    }
}
</script>

<style lang="scss" scoped>
    .card_five{
        width: 100%;
        height: 248px;
        border-radius: 16px;
        position: relative;
        img{
            width: 100%;
            height: 100%;
            border-radius: 16px;
            object-fit: cover;
        }
        .card_con{
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(55,55,55,0.8), rgba(55,55,55,0));
            border-radius: 16px;
            position: absolute;
            top: 0;
            left: 0;
            .companyname{
                padding:24px 36px 0 0;
                justify-content: flex-end;
                .name{
                    width: max-content;
                    height: 28px;
                    background: #3D3D3D;
                    border-radius: 14px;
                    line-height: 28px;
                    padding: 0 17px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
            .name_job{
                margin-top: 120px;
                padding-left: 136px;
                .name{
                    font-size: 24px;
                    font-weight: bold;
                    color: #FFFFFF;
                    margin-bottom: 5px;
                }
                .jposi{
                    font-size: 16px;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
            .thumb{
                width: 96px;
                height: 96px;
                border: 4px solid #FFFFFF;
                box-shadow: 0px 6px 20px 0px rgba(25,25,25,0.1);
                border-radius: 50%;
                position: absolute;
                left: 20px;
                bottom: -16px;
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
        }
    }
</style>