<template>
    <el-dialog 
        :title="`请输入${phone}的验证码`" 
        :visible.sync="centerDialogVisible" 
        :append-to-body="true"
        width="30%"
    >
        <div class="d-flex">
            <el-input v-model="smsCode"></el-input>  
            <el-button 
                style="margin-left: 20px" 
                type="text"
                :disabled="countDown !== 0 ? true : false"
                @click="getSendCode"
            >
                {{countDown ? `重新发送(${countDown})` : "获取验证码"}}
            </el-button>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="sure" :loading="load">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import { getsmsCode,checkSmsCode } from '@/api/companyManage.js';

    export default {
        data() {
            return {
                centerDialogVisible: false,
                phone: '',
                smsCode: '',
                countDown: 0,
                load: false,
                timer: null,
            };
        },
        methods: {
            getSendCode(){
                if (this.countDown) return;
                let opts = {
                    phone: this.phone,
                    type: 1
                };
                getsmsCode(opts).then(res=>{
                    if(res.code == 200){
                        this.countDown = 60;
                        this.timer = setInterval(() => {
                            this.countDown--;
                            if (this.countDown === 0) {
                                clearInterval(this.timer);
                                this.timer = null;
                            }
                        }, 1 * 1000);
                    }else{
                        this.$message.error(res.message);
                    }
                })
            },
            init(phone){
                this.phone = phone;
                this.centerDialogVisible = true;
            },
            close(){
                this.smsCode = '';
                this.countDown = 0;
                clearInterval(this.timer);
                this.timer = null;
                this.centerDialogVisible = false;
            },
            sure(){
                if(!this.smsCode){
                    this.$message.error('验证码不可为空！');
                    return;
                };
                this.load = true;
                let opts = {
                    phone: this.phone,
                    smsCode: this.smsCode
                };
                checkSmsCode(opts).then(res=>{
                    this.load = false;
                    if(res.code == 200){
                        this.$emit('success');
                        this.close();
                    }else{
                        this.$message.error(res.message);

                    }
                })
            },
        },
        mounted() {

        },
    }
</script>

<style scoped lang="scss">
    ::v-deep{
        .el-input__inner{
            width: 100% !important;
        }
    }
</style>