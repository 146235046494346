<template>
    <div class="card_three d-flex" v-if="info">
        <div class="title d-flex">
            <div class="line"></div>
            <div class="companyname">{{info.companyName}}</div>
        </div>
        <div class="card_con">
            <div class="thumb">
                <img :src="info.logo ? info.logo : require('../../../../assets/images/card1_user.png')" alt="">
            </div>
            <div class="name_job">
                <div class="name">{{info.name}}</div>
                <div class="jposi">{{info.position || '职位'}}</div>
            </div>
            <div class="tel_email">
                <div class="tel">{{info.phone || '电话'}}</div>
                <div class="email">{{info.email || '邮箱'}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            info: Object
        }
    }
</script>

<style lang="scss" scoped>
    .card_three{
        width: 100%;
        height: 270px;
        box-shadow: 0px 6px 20px 0px rgba(25,25,25,0.06);
        border-radius: 16px;
        background-image: url('../../../../assets/images/card_bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 33px 24px 0;
        .title{
            width: 50%;
            min-height: 16px;
            .line{
                position: relative;
                height: 16px;
                &::before{
                    content: '';
                    width: 3px;
                    height: 16px;
                    background: #51CDCB;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                &::after{
                    content: '';
                    width: 3px;
                    height: 16px;
                    background: #51CDCB;
                    position: absolute;
                    left: 9px;
                    top: 0;
                }
            }
            .companyname{
                font-size: 16px;
                font-weight: 400;
                color: #F0EACF;
                margin-left: 18px;
                line-height: 16px;
            }
        }
        .card_con{
            width: 50%;
            position: relative;
            .thumb{
                width: 60px;
                height: 60px;
                border-radius: 4px;
                position: absolute;
                top: 7px;
                left: 19.2px;
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 4px;
                    object-fit: cover;
                }
            }
            .name_job{
                position: absolute;
                top: 82px;
                left: 19px;
                .name{
                    font-size: 24px;
                    font-weight: bold;
                    color: #F0EACF;
                    margin-bottom: 5px;
                }
                .jposi{
                    font-size: 16px;
                    font-weight: 400;
                    color: #F0EACF;
                }
            }
            .tel_email{
                position: absolute;
                left: 40px;
                bottom: 37px;
                .tel{
                    font-size: 14px;
                    font-weight: 400;
                    color: #F0EACF;
                    margin-bottom: 5px;
                }
                .email{
                    font-size: 14px;
                    font-weight: 400;
                    color: #F0EACF;
                }
            }
        }
    }
</style>