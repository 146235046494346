<template>
    <el-dialog :modal-append-to-body="false" :title="type == 'add' ? '添加视频' : '编辑视频'" :visible.sync="dialogVisible"
        width="50%" center @close="close">
        <div class="cus_body">
            <el-form :model="formInfo" ref="videoForm" :rules="rules" label-width="100px" class="form_con">
                <el-form-item label="视频名称" prop="videoTitle">
                    <el-input placeholder="请输入视频名称" v-model="formInfo.videoTitle"></el-input>
                </el-form-item>
                <el-form-item label="封面图" prop="videoCover">
                    <el-upload 
                        :action="uploadUrl"
                        :class="{ disabled: fileList.length > 0 }"
                        :file-list="fileList"
                        list-type="picture-card"
                        :limit="1"
                        :on-remove="handleRemove"
                        :on-success="handleSuccess"
                    >
                        <i slot="default" class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="视频" prop="videoUrl">
                    <el-radio-group v-model="radio" class="mar_b" v-if="!formInfo.videoUrl">
                        <el-radio label="本地视频"></el-radio>
                        <el-radio label="视频链接"></el-radio>
                    </el-radio-group>
                    <div class="d-flex" v-loading="upLoading">
                        <div class="videolist d-flex" v-if="videoList.length">
                            <div class="video_item" v-for="(item,index) in videoList" :key="index">
                                <video controls>
                                    <source :src="ossUrl + item.response.data">
                                </video>
                                <div class="del d-flex" @click="videoRemove(index)">
                                    <i class="el-icon-delete"></i>
                                </div>
                            </div>
                        </div>
                        <template v-if="radio == '本地视频'">
                            <el-upload :action="uploadUrl" accept=".mp4" list-type="picture-card" :show-file-list="false"
                                :file-list="videoList" :on-success="videoSuccess" :before-upload="beforeUploadVideo"
                                :on-progress="videoProgress"
                                :limit="1" :class="{ disabled: videoList.length > 0 }"
                            >
                                <i slot="default" class="el-icon-plus"></i>
                            </el-upload>
                        </template>
                        <template v-else>
                            <el-input placeholder="请输入视频链接" v-model="formInfo.videoUrl"></el-input>
                        </template>
                    </div>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input placeholder="排序越大越靠前" v-model="formInfo.sort"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" :loading="load" @click="save">保存</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { addCardvideo,updataCardvideo } from '@/api/companyManage.js';

export default {
    data() {
        return {
            dialogVisible: false,
            type: 'add',
            uploadUrl: this.$store.state.uploadingUrl,
            ossUrl: this.$store.state.ossUrl,
            fileList:[],
            videoList:[],
            videoId:'',
            cardId: '',
            formInfo: {
                videoUrl: '',
                videoCover: '',
                videoTitle: '',
                sort:'',
            },
            rules: {
                videoTitle: [{ required: true, message: "请输入视频名称!", trigger: "blur" }],
                videoCover: [{ required: true, message: "请上传封面图！", trigger: "blur" }],
                videoUrl: [{ required: true, message: "请上传视频！", trigger: "blur" }],
            },
            load: false,
            radio: '本地视频',
            upLoading: false,
        }
    },
    methods: {
        init(type,cardId,data = {}) {
            this.type = type;
            this.cardId = cardId;
            if(type == 'edit'){
                this.filterData(data);
            };
            this.dialogVisible = true;
        },
        close() {
            this.fileList = [];
            this.videoList = [];
            this.formInfo = {
                videoUrl: '',
                videoCover: '',
                videoTitle: '',
                sort:'',
            };
            this.load = false;
            this.$refs.videoForm.resetFields();
            this.dialogVisible = false;
        },
        handleRemove(file, fileList) {
            this.fileList = [];
            this.formInfo.videoCover = '';
        },
        handleSuccess(response, file, fileList) {
            this.$message({
                message: "上传成功",
                type: "success",
            });
            this.fileList = fileList;
            this.formInfo.videoCover = this.ossUrl +  file.response.data;
        },
        //视频
        videoRemove(idx) {
            this.videoList.splice(idx, 1);
            this.formInfo.videoUrl = '';
            // this.delVideo();
        },
        videoProgress(event, file, fileList){
            this.upLoading = true;
            if(event.percent == 100){
                this.upLoading = false;
            }
        },
        // delVideo(){
        //     let opts = {
        //         videoId: this.videoId
        //     };
        //     try{
        //         delCardvideo(opts).then(res=>{
        //             if(res.code == 200){
        //                 this.$message.success(res.message);
        //                 this.$emit('success');
        //                 this.close();

        //             }else{
        //                 this.$message.success(res.message);
        //             }
        //         })
        //     }catch(err){

        //     }
        // },
        videoSuccess(response, file, fileList) {
            this.$message({
                message: "上传成功",
                type: "success",
            });
            this.videoList = fileList;
            this.formInfo.videoUrl = this.ossUrl +  file.response.data;
        },
        beforeUploadVideo(file) {
            const isLt2M = file.size / 1024 / 1024 < 30;
            if (!isLt2M) {
                this.$message.error("上传视频大小不能超过 30MB!");
            }
            return isLt2M;
        },
        filterData(data){
            let { cardId,videoUrl,videoCover,videoTitle,sort,videoId } = data;
            this.videoId = videoId;
            this.cardId = cardId;
            this.formInfo = {
                videoUrl,
                videoCover,
                videoTitle,
                sort,
            };
            if(videoCover){
                this.fileList = [{
                    url: videoCover,
                    uid: 1,
                    response:{
                        data: videoCover.slice(this.findStr(videoCover,'/',2) + 1)
                    }
                }];
            };
            this.videoList = [{
                url: videoUrl,
                uid: 1,
                response:{
                    data: videoUrl.slice(this.findStr(videoUrl,'/',2) + 1)
                }
                
            }]                 
        },
        findStr(str,cha,num){
            let x=str.indexOf(cha);
            for(let i=0;i<num;i++){
                x=str.indexOf(cha,x+1);
            }
            return x;
        },
        save(){
            this.$refs.videoForm.validate((valid) => {
                if(valid){
                    if(!this.formInfo.videoCover){
                            this.$message.error('请上传封面图！');
                            return;
                        };
                        if(!this.formInfo.videoUrl){
                            this.$message.error('请上传视频！');
                            return;
                        };
                    let opts = { 
                        cardId: this.cardId,
                        ...this.formInfo 
                    };
                    this.load = true;
                    if(this.type == 'add'){
                        addCardvideo(opts).then(res=>{
                            if(res.code == 200){
                                this.load = false;
                                this.$message.success(res.message);
                                this.$emit('success');
                                this.close();
                            }else{
                                this.load = false;
                                this.$message.success(res.message);
                            }
                        }).catch(err=>{
                            this.load = false;
                        })
                    }else{
                        opts.videoId = this.videoId;
                        updataCardvideo(opts).then(res=>{
                            if(res.code == 200){
                                this.load = false;
                                this.$message.success(res.message);
                                this.$emit('success');
                                this.close();
                            }else{
                                this.load = false;
                                this.$message.success(res.message);
                            }
                        }).catch(err=>{
                            this.load = false;
                        })
                    }
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.cus_body {
    width: 100%;

    .videolist{
        flex-wrap: wrap;
        .video_item{
            width: 148px;
            height: 148px;
            margin-right: 10px;
            margin-bottom: 10px;
            position: relative;

            video{
                width: 148px;
                height: 148px;
                object-fit: cover;

            }
            .del{
                width: 20px;
                height: 20px;
                position: absolute;
                top: 0;
                right: 0;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                i{
                    color: #51CBCD;
                }
            }
        }
    }
}

::v-deep {
    .el-input__inner{
        width: 100% !important;
    }
    .disabled .el-upload--picture-card, .disabled .el-upload--text {
        display: none;
    }
}
</style>