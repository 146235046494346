<template>
    <div class="card_seven" v-if="info">
        <div class="avatar">
            <img :src="info.logo ? info.logo : require('../../../../assets/images/card1_user.png')" alt="">
        </div>
        <div class="name">{{info.name}}</div>
        <div class="jposi">{{info.position || '职位'}}</div>
        <div class="companyname">{{info.companyName}}</div>
    </div>
</template>

<script>
    export default {
        props:{
            info: Object
        }
    }
</script>

<style lang="scss" scoped>
    .card_seven{
        width: 100%;
        text-align: center;
        .avatar{
            width: 158px;
            height: 158px;
            box-shadow: 0px 6px 20px 0px rgba(25,25,25,0.06);
            margin: 0 auto 24px;
            border-radius: 50%;
            border: 6px solid #FFFFFF;
            img{
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
            }
        }
        .name{
            font-size: 24px;
            font-weight: bold;
            color: #3D3D3D;
        }
        .jposi{
            font-size: 16px;
            font-weight: 400;
            color: #7B7B7B;
            margin: 12px 0 24px;
        }
        .companyname{
            font-size: 16px;
            font-weight: 400;
            color: #51CDCB;
        }
    }
</style>