<template>
    <div class="card_four" v-if="info">
        <img :src="info.logo ? info.logo : require('../../../../assets/images/card1_user.png')" alt="">
        <div class="card_con">
            <div class="header d-flex">
                <img src="../../../../assets/images/down.png" alt="">
                <div class="companyname">{{info.companyName}}</div>
            </div>
            <div class="footer d-flex">
                <div class="name">{{info.name}}</div>
                <div class="jposi">{{info.position || '职位'}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        info: Object
    }
}
</script>

<style lang="scss" scoped>
    .card_four{
        width: 100%;
        height: 270px;
        border-radius: 16px;
        position: relative;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            border-radius: 16px;
            object-fit: cover;
        }
        .card_con{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(rgba(55,55,55,0.8), rgba(55,55,55,0) 55%,rgba(55,55,55,0.8));
            .header{
                padding-top: 24px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                img{
                    width: 10px;
                    height: 10px;
                }
                .companyname{
                    font-size: 16px;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-top: 10px;
                    margin-bottom: 133px;
                }
            }
            .footer{
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .name{
                    font-size: 24px;
                    font-weight: bold;
                    color: #FFFFFF;
                }
                .jposi{
                    font-size: 16px;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }
    }
</style>