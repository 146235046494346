<template>
    <div class="businesscard_con">
        <el-tabs v-model="activeName">
            <el-tab-pane label="名片信息" name="first">
                <el-form
                    :model="formInfo"
                    ref="cardruleForm"
                    label-width="100px"
                    class="form_con"
                    :rules="rules"
                    v-loading="load"
                >
                    <el-form-item label="企业名称" prop="companyName">
                        <el-input  placeholder="请输入企业名称" disabled v-model="formInfo.companyName"></el-input>
                    </el-form-item>
                    <el-form-item label="职位">
                        <el-input placeholder="请输入职位" v-model="formInfo.position"></el-input>
                    </el-form-item>
                    <el-form-item label="头像">
                        <el-upload
                            :action="uploadUrl"
                            :class="{ disabled: fileList.length > 0 }"
                            :file-list="fileList"
                            list-type="picture-card"
                            :limit="1"
                            :on-remove="handleRemove"
                            :on-success="handleSuccess"
                        >
                            <i slot="default" class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="姓名" prop="name">
                        <el-input placeholder="请输入姓名" v-model="formInfo.name"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="phone">
                        <div class="d-flex row">
                            <el-input placeholder="请输入手机号" v-model="formInfo.phone" :disabled="pboneDisabled"></el-input>
                            <el-button type="text" class="ml10" @click="editPhone(formInfo.phone)">修改手机号</el-button>
                        </div>
                    </el-form-item>
                    <el-form-item label="微信号">
                        <el-input placeholder="请输入微信号" v-model="formInfo.personalWx"></el-input>
                    </el-form-item>
                    <el-form-item label="企业微信">
                        <el-input placeholder="请输入企业微信" v-model="formInfo.companyWx"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱">
                        <el-input placeholder="请输入邮箱" v-model="formInfo.email"></el-input>
                    </el-form-item>
                    <el-form-item label="个人简介">
                        <div class="cus_con">
                            <template>
                                <el-upload
                                    :action="uploadUrl"
                                    :class="{ disabled: audioList.length>0 }"
                                    accept=".mp3"
                                    :limit="1"
                                    :file-list="audioList"
                                    :on-remove="audioRemove"
                                    :on-success="audioSuccess"
                                    :before-upload="beforeAudio"
                                >
                                    <div class="adveocon">点击上传语音文件</div>
                                </el-upload>
                            </template>
                            <el-input
                                type="textarea"
                                size="medium"
                                :rows="6"
                                placeholder="请输入文字介绍"
                                v-model="formInfo.briefIntroduction"
                            >
                            </el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="视频展示">
                        <div class="d-flex">
                            <div class="videolist d-flex" v-if="videoList.length">
                                <div class="video_item" v-for="(item,index) in videoList" :key="index">
                                    <video :poster="item.videoCover">
                                        <source :src="item.videoUrl">
                                    </video>
                                    <div class="mask" @click.stop="editvideo(item)"></div>
                                    <div class="del d-flex" @click.stop="videocardRemove(item)">
                                        <i class="el-icon-delete"></i>
                                    </div>
                                </div>
                            </div>
                            <div @click="addvideo">
                                <el-upload
                                    disabled
                                    :action="uploadUrl"
                                    accept=".mp4"
                                    list-type="picture-card"
                                    :show-file-list="false" 
                                >
                                    <i slot="default" class="el-icon-plus"></i>
                                </el-upload>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="图片展示">
                        <el-upload
                            :action="uploadUrl"
                            list-type="picture-card"
                            :file-list="imgList"
                            :on-remove="imgRemove"
                            :on-success="imgSuccess"
                        >
                            <i slot="default" class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="热销商品">
                        <el-button size="mini" type="primary" @click="changeHot">请选择热销商品</el-button>
                        <div class="product d-flex" v-if="hotList.length">
                            <div class="item" v-for="(item,index) in hotList" :key="index">
                                <img :src="item.goodsPhoto" alt="">
                                <div class="price">￥{{item.price}}</div>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <div class="d-flex justify-content-center" style="width:50%">
                            <el-button type="primary" :loading="isSave" @click="save">保存</el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="名片样式" name="second">
                <card-style @change="setStyle" ref="cardStyle" />
            </el-tab-pane>
        </el-tabs>

        <add-video ref="addVideo" @success="updataData" />
        <hot-goods ref="hotGoods" @success="queryGoods" />
        <vercode-pop ref="vercodePop" @success="verSuccess" />
    </div>
</template>

<script>
    import cardStyle from './components/cardStyle';
    import hotGoods from './components/hotgoods';
    import addVideo from './components/addvideo';
    import vercodePop from './components/vercodepop';
    import { queryCardinfo,updataCardinfo,queryHotgoods,delCardvideo } from '@/api/companyManage.js';

    const checkTel = (rule, value, callback) => {
        if (!value || !(/^1[3456789]\d{9}$/.test(value))) {
          return callback(new Error('请输入正确的手机号！'));
        }else{
            return callback()
        }
    };

    export default {
        components:{
            cardStyle,
            hotGoods,
            addVideo,
            vercodePop
        },
        data(){
            return {
                activeName: 'first',
                cardId: '',
                companyId: '',
                uploadUrl:this.$store.state.uploadingUrl,
                ossUrl:this.$store.state.ossUrl,
                fileList:[],
                audioList:[],
                videoList: [],
                imgList: [],
                formInfo:{
                    companyName: '',
                    position: '',
                    logo:'',
                    name: '',
                    phone: '',
                    personalWx: '',
                    companyWx: '',
                    email: '',
                    briefIntroduction: '',
                    soundRecording: '',
                    photo: '',
                },
                isSave: false,
                rules: {
                    companyName: [{ required: true, message: "请输入企业名称", trigger: "blur" }],
                    name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
                    phone: [{ validator: checkTel, required: true, trigger: "blur" }],
                },
                cardStyle: 0,
                isPublic: false,
                cardTemplate: '',
                soundTime: 0,
                hotList: [],
                load: false,
                pboneDisabled: true,
            }
        },
        methods:{
            verSuccess(){
                this.pboneDisabled = false;
            },
            //头像
            handleRemove(file, fileList) {
                this.fileList = [];
                this.formInfo.logo = '';
            },
            handleSuccess(response, file, fileList) {
                this.$message({
                    message: "上传成功",
                    type: "success",
                });
                this.fileList = fileList;
                this.formInfo.logo = this.ossUrl +  file.response.data;
            },
            //音频
            audioRemove(){
                this.audioList = [];
                this.formInfo.soundRecording = '';
            },
            audioSuccess(response, file, fileList){
                this.$message({
                    message: "上传成功",
                    type: "success",
                });
                this.audioList = fileList;
                this.formInfo.soundRecording = this.ossUrl +  file.response.data;
            },
            async beforeAudio(file){
                let time = await this.handleTime(file);
                this.soundTime = time;
            },
            //图片
            imgRemove(file,fileList){
                this.imgList = fileList;
            },
            imgSuccess(response, file, fileList){
                this.$message({
                    message: "上传成功",
                    type: "success",
                });
                this.imgList = fileList;
            },
            addvideo(){
                this.$refs.addVideo.init('add',this.cardId);
            },
            editvideo(item){
                this.$refs.addVideo.init('edit',this.cardId,item);
            },
            videocardRemove(item){
                let opts = {
                    videoId: item.videoId
                };
                try{
                    delCardvideo(opts).then(res=>{
                        if(res.code == 200){
                            this.$message.success(res.message);
                            this.getData();
                        }else{
                            this.$message.success(res.message);
                        }
                    })
                }catch(err){

                }
            },
            changeHot(){
                let ids = [];
                if(this.hotList.length){
                    ids = this.hotList;
                };
                this.$refs.hotGoods.init(this.cardId,this.companyId,ids);
            },
            setStyle(val){
                this.cardStyle = val;
            },
            editPhone(phone){
                this.$refs.vercodePop.init(phone);
            },
            save(){
                this.$refs.cardruleForm.validate((valid) => {
                    if(valid){
                        if(this.formInfo.email){
                            let email_reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
                            if(!email_reg.test(this.formInfo.email)){
                                this.$message.error('请输入正确的邮箱！')
                                return;
                            };
                        };
                        let photo = this.imgList.map(v => (this.ossUrl + v.response.data));
                            photo = photo.join(',')
                        let opts = {
                            ...this.formInfo,
                            photo,
                            isPublic: this.isPublic,
                            companyId: this.companyId,
                            cardId: this.cardId,
                            cardTemplate: this.cardTemplate,
                            soundTime: this.soundTime,
                            style: this.cardStyle,
                        };
                        this.isSave = true;
                        try{
                            updataCardinfo(opts).then(res=>{
                                if(res.code == 200){
                                    this.$message.success(res.message);
                                    this.isSave = false;
                                    this.getData();
                                    this.pboneDisabled = true;
                                    this.$refs.cardStyle.getData();
                                }else{
                                    this.$message.success(res.message);
                                    this.isSave = false; 
                                }
                            }).catch(err=>{
                                this.isSave = false;
                            })
                        }catch(err){
                            this.isSave = false;
                        }
                    }
                });
            },
            getData(){
                let opts = {
                    userId: localStorage.getItem('userId')
                };
                this.load = true;
                queryCardinfo(opts).then(res=>{
                    if(res.code == 200){
                        this.cardId = res.data.cardId;
                        this.companyId = res.data.companyId;
                        this.cardStyle = res.data.style;
                        this.isPublic = res.data.isPublic;
                        this.cardTemplate = res.data.cardTemplate
                        Object.keys(res.data).forEach((v,i)=>{
                            Object.keys(this.formInfo).forEach((a,b)=>{
                                if(a == v){
                                    this.formInfo[a] = res.data[v];
                                }
                            })
                        });
                        if(res.data.logo){
                            this.fileList = [{
                                url: res.data.logo,
                                uid: 1,
                                response:{
                                    data: res.data.logo.slice(this.findStr(res.data.logo,'/',2) + 1)
                                }
                            }];
                        };
                        if(res.data.soundRecording){
                            this.audioList = [{
                                url: res.data.soundRecording,
                                uid: 1,
                                response:{
                                    data: res.data.soundRecording.slice(this.findStr(res.data.soundRecording,'/',2) + 1)
                                }
                            }];
                        };
                        this.$nextTick(()=>{
                            if(res.data.videoList && res.data.videoList.length){
                                this.videoList = [];
                                this.videoList = res.data.videoList;
                            }else{
                                this.videoList = [];
                            }
                        });
                        if(res.data.photo && res.data.photo != '暂无'){
                            this.imgList = [];
                            let img = res.data.photo.split(',');
                            img.forEach((v,i)=>{
                                this.imgList.push({
                                    url: v,
                                    uid: i+1,
                                    response:{
                                        data: v.slice(this.findStr(v,'/',2) + 1)
                                    }
                                })
                            });
                        }else{
                            this.imgList = [];
                        }

                        this.getHotlist(res.data.cardId);
                        this.$forceUpdate()
                        this.load = false;
                    }else{
                        this.load = false;
                    }
                }).catch(err=>{
                    this.load = false;
                })
            },
            findStr(str,cha,num){
				let x=str.indexOf(cha);
				for(let i=0;i<num;i++){
					x=str.indexOf(cha,x+1);
				}
				return x;
			},
            handleTime(file) {
                return new Promise((resolve, reject) => {
                    let url = URL.createObjectURL(file)
                    let element = new Audio(url)
                    element.addEventListener('loadedmetadata', () => {
                        const time = Math.round(element.duration * 100) / 100
                        resolve(time)
                    })
                    element.addEventListener('error', () => resolve(0));
                })
            },
            updataData(){
                this.getData();
            },
            getHotlist(cardId){
                let opts = {cardId};
                queryHotgoods(opts).then(res=>{
                    if(res.code == 200){
                        if(res.data && res.data.length){
                            res.data.forEach(v=>{
                                v.price = (v.price / 100 ).toFixed(2);
                            })
                            this.hotList = res.data;
                        }else{
                            this.hotList = [];
                        }
                    }else{
                        this.hotList = [];
                    }
                })
            },
            queryGoods(){
                this.getHotlist(this.cardId);
            },
        },
        mounted(){
            this.getData();
        },
    }
</script>

<style lang="scss" scoped>
    .businesscard_con{
        width: 100%;
        min-height: 100%;
        background: #fff;

        .form_con{
            margin-top: 24px;
        }
        .cus_con{
            width: 100%;
        }
        
        .adveocon{
            text-align: left;
            color: #51CBCD;
        }

        .row{
            width: 30%;
            /deep/.el-input__inner{
                width: 100% !important;
            }
        }

        .product{
            width: 40%;
            flex-wrap: wrap;
            margin-top: 12px;
            .item{
                width: 30%;
                margin-bottom: 10px;
                margin-right: 10px;
                img{
                    width: 100%;
                    height: 150px;
                    object-fit: cover;
                }
                .price{
                    font-size: 14px;
                    color: #000;
                    text-align: center;
                    line-height: 18px;
                }
            }
        }
        .videolist{
            flex-wrap: wrap;
            .video_item{
                width: 148px;
                height: 148px;
                margin-right: 10px;
                margin-bottom: 10px;
                position: relative;

                video{
                    width: 148px;
                    height: 148px;
                    object-fit: cover;
    
                }
                .mask{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    cursor: pointer;
                }
                .del{
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    i{
                        color: #51CBCD;
                    }
                }
            }
        }
    }
    ::v-deep{
        .el-input__inner{
            width: 50%;
        }
        .el-textarea{
            width: 50%;
        }
        .el-upload-list--text{
            width: 50%;
        }
        .el-upload--text{
            width: 240px;
            height: 24px;
            border: 0;
        }
        .disabled .el-upload--picture-card, .disabled .el-upload--text {
            display: none;
        }
    }
</style>