<template>
    <div class="card_one" v-if="info">
        <div class="title d-flex">
            <div class="line"></div>
            <div class="txt">{{info.companyName}}</div>
        </div>
        <div class="body_con d-flex">
            <div class="left">
                <div class="name mar_b">{{info.name}}</div>
                <div class="jposi">{{info.position}}</div>
                <div class="cus_img_val d-flex mar_b">
                    <img src="../../../../assets/images/rjt.png" alt="">
                    <div class="val">{{info.phone || '电话'}}</div>
                </div>
                <div class="cus_img_val d-flex">
                    <img src="../../../../assets/images/rjt.png" alt="">
                    <div class="val">{{info.email || '邮箱'}}</div>
                </div>
            </div>
            <div class="avatar">
                <img :src="info.logo ? info.logo : require('../../../../assets/images/card1_user.png')" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            info: Object
        }
    }
</script>

<style lang="scss" scoped>
    .card_one{
        width: 100%;
        height: 270px;
        background: #FFFFFF;
        box-shadow: 0px 6px 20px 0px rgba(25,25,25,0.06);
        border-radius: 2px;
        padding: 32px 24px 0;
        .title{
            width: 100%;
            align-items: center;
            .line{
                width: 4px;
                height: 16px;
                background: #51CDCB;
            }
            .txt{
                font-size: 16px;
                font-weight: 400;
                color: #51CDCB;
                margin-left: 6px;
            }
        }
        .body_con{
            width: 100%;
            padding-top: 50px;
            justify-content: space-between;
            .left{
                width: calc(100% - 140px);
                .name{
                    font-size: 24px;
                    font-weight: bold;
                    color: #3D3D3D;
                }
                .jposi{
                    font-size: 16px;
                    font-weight: 400;
                    color: #A9A9A9;
                    margin-bottom: 25px;
                }
                .cus_img_val{
                    align-items: center;
                    img{
                        width: 16px;
                        height: 11px;
                        margin-right: 6px;
                    }
                    .val{
                        font-size: 14px;
                        font-weight: 400;
                        color: #7B7B7B;
                    }
                }
            }
            .avatar{
                width: 132px;
                height: 132px;
                border-radius: 8px;
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                }
            }
        }
    }
    .mar_b{
        margin-bottom: 10px;
    }
</style>