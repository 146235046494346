<template>
    <div class="card_two" v-if="info">
        <img :src="info.logo ? info.logo : require('../../../../assets/images/card1_user.png')" alt="">
        <div class="card_con d-flex">
            <div class="left">
                <div class="name">{{info.name}}</div>
                <div class="jposi">{{info.position || '职位'}}</div>
                <div class="cus_img_val d-flex">
                    <img src="../../../../assets/images/tel.png" alt="">
                    <div class="val">{{info.phone || '电话'}}</div>
                </div>
            </div>
            <div class="thumb">
                <img :src="info.companyLogo ? info.companyLogo : require('../../../../assets/images/qya.png')" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        info: Object
    }
}
</script>

<style lang="scss" scoped>
.card_two {
    width: 100%;
    height: 440px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .card_con {
        width: 408px;
        height: 140px;
        background: #FFFFFF;
        box-shadow: 0px 6px 20px 0px rgba(25, 25, 25, 0.06);
        position: absolute;
        top: 352px;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 24px 24px 20px;
        justify-content: space-between;
        align-items: center;
        .left{
            width: calc(100% - 100px);
            .name{
                font-size: 24px;
                font-weight: bold;
                color: #3D3D3D;
                margin-bottom: 10px;
            }
            .jposi{
                font-size: 16px;
                font-weight: 400;
                color: #7B7B7B;
                margin-bottom: 26px;
            }
            .cus_img_val{
                align-items: center;
                img{
                    width: 18px;
                    height: 18px;
                }
                .val{
                    font-size: 14px;
                    font-weight: 400;
                    color: #A6A6A6;
                    margin-left: 6px;
                }
            }
        }
        .thumb{
            width: 70px;
            height: 70px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
</style>