<template>
    <el-dialog 
        :modal-append-to-body="false" 
        title="热销商品" 
        :visible.sync="dialogVisible" 
        width="50%"
        center
        @close="close"
    >
        <div class="cus_body">
            <div>
                <el-select
                    @change="typeName"
                    v-model="categoriesValue"
                    class="w120 mb10 mr10"
                    placeholder="商品分类"
                >
                    <el-option
                        v-for="(item, index) in options"
                        :key="index"
                        :label="item.name"
                        :value="item.goodstypeId"
                    >
                    </el-option>
                </el-select>
                <el-input
                    style="width: 200px;"
                    placeholder="请输入搜索内容"
                    clearable
                    @clear="search"
                    v-model="filterName"
                />
                <el-button class="mar-l" type="primary" @click="search">搜索</el-button>
            </div>
            <common-table
                :tableData="tableData"
                @handleSelectionChange="selectItem"
                :loading="loading"
                :total="total"
                @handleCurrentChange="CurrentChange"
                @handleSizeChange="SizeChange"
                :rowKey="getRowKeys"
                :currentPage="currentPage"
                ref="myTableSelect"
            >
                <template slot="table">
                    <el-table-column type="selection" :reserveSelection="true" />
                    <el-table-column prop="goodPhoto" align="center" label="商品图片">
                        <template slot-scope="scope">
                            <el-image
                                fit="cover"
                                v-if="scope.row.goodPhoto"
                                style="width: 100px; height: 80px;"
                                :src="scope.row.goodPhoto"
                            ></el-image>
                            <p
                                v-else
                                style="width: 100px; height: 80px;line-height: 80px;margin-bottom:-10px"
                            >
                                暂无图片
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="goodsName" align="center" :show-overflow-tooltip="true" label="商品名称" />
                    <el-table-column prop="goodsTypeName" align="center" label="分类" />
                    <el-table-column prop="maxPrice" align="center" label="价格" />
                </template>
            </common-table>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" :loading="load" @click="save">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import commonTable from "@/components/common/commonTable";
    import { getGoodsInfo,getGoodsTypeInfo } from "@/api/coupon.js";
    import { addCardHotgoods } from '@/api/companyManage.js';

    export default{
        components:{
            commonTable
        },
        data(){
            return {
                dialogVisible: false,
                cardId: '',
                companyId: '',
                tableData:[],
                options:[],
                categoriesValue: "全部",
                goodstypeId: '',
                filterName: '',
                total:0,
                pageNum:1,
                pageSize:10,
                currentPage: 1,
                loading:false,
                selectArr:[],
                load: false,
            }
        },
        methods:{
            getRowKeys(row) {
                return row.goodsId;
            },
            init(cardId,companyId,ids){
                this.cardId = cardId;
                this.companyId = companyId;
                this.selectArr = ids;
                this.getType();
                this.getList(()=>{
                    this.$nextTick(()=>{
                        this.setSelect(ids);
                    })
                });
                this.dialogVisible = true;
            },
            close(){
                this.selectArr = [];
                this.$refs.myTableSelect.clearValue();
                this.filterName = '';
                this.categoriesValue = '全部';
                this.goodstypeId = '';
                this.dialogVisible = false;
                this.load = false;
            },
            selectItem(val){
                this.selectArr = val;
            },
            typeName(value) {
                this.goodstypeId = value
            },
            CurrentChange(num){
                this.pageNum = num;
                this.getList(()=>{
                    this.$nextTick(()=>{
                        this.setSelect(this.selectArr);
                    })
                });
            },
            search(){
                this.getList(()=>{
                    this.$nextTick(()=>{
                        this.setSelect(this.selectArr);
                    })
                });
            },
            SizeChange(num){
                this.pageSize = num;
                this.pageNum = 1;
                this.currentPage = 1;
                this.getList(()=>{
                    this.$nextTick(()=>{
                        this.setSelect(this.selectArr);
                    })
                });
            },
            save(){
                // if(!this.selectArr.length){
                //     this.$message.error('请选择商品！');
                //     return;
                // };
                let goodIds = this.selectArr.map(v => v.goodsId);
                let opts = {
                    cardId: this.cardId,
                    companyId:this.companyId,
                    goodIds
                };
                this.load = true;
                try{
                    addCardHotgoods(opts).then(res=>{
                        if(res.code == 200){
                            this.$emit('success');
                            this.load = false;
                            this.close();
                        }else{
                            this.load = false; 
                        }
                    }).catch(err=>{
                        this.load = false; 
                    })
                }catch(err){
                    this.load = false;
                }
            },
            getList(callback){
                let opts = {
                    goodsName: this.filterName,
                    goodstypeId: this.goodstypeId,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize
                };
                this.loading = true;
                try{
                    getGoodsInfo(opts).then(res=>{
                        if(res.code == 200){
                            let data =  JSON.parse(res.data.pageInfo);
                            data.list.forEach(v=>{
                                if (v.goodPhoto) {
                                    v.maxPrice  = (v.maxPrice / 100 ).toFixed(2);
                                    v.goodPhoto = v.goodPhoto.split(",")[0];
                                }
                            });
                            this.tableData = data.list;
                            this.total = data.total;
                            callback && callback();
                            this.loading = false;
                        }else{
                            this.tableData = [];
                            this.loading = false;
                        }
                    })
                }catch(err){
                    this.loading = false;
                }
            },
            setSelect(ids){
                if(ids.length){
                    ids.forEach(v=>{
                        this.tableData.forEach((j,i)=>{
                            if(v.goodsId == j.goodsId){
                                this.$refs.myTableSelect.setValue(j);
                            }
                        })
                    })
                };
            },
            getType(){
                getGoodsTypeInfo({}).then(res=>{
                    if(res.code == 200){
                        res.data.unshift({ name: "全部" });
                        if(res.data.length){
                            this.options = res.data;
                        }
                    }
               });
            },
        },
        mounted(){}
    }
</script>

<style lang="scss" scoped>
.cus_body{
    width: 100%;
}
.mar-l{
    margin-left: 12px;
}
::v-deep{
    .el-input__inner{
        width: 100% !important;
    }
}
</style>