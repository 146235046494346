<template>
    <div class="style_con d-flex">
        <div class="label"><span>* </span> 样式选择</div>
        <div class="cus_radio d-flex">
            <el-radio v-for="(item, index) in cardList" :key="index" :label="item.type" v-model="cuRidx"
                @input="changeRadio">样式{{ index + 1 }}</el-radio>
        </div>
        <div class="card_container">
            <component :is="componentCur" :info="info"></component>
        </div>
    </div>
</template>

<script>
import cardOne from './cardOne';
import cardTwo from './cardTwo';
import cardThree from './cardThree';
import cardFour from './cardFour';
import cardFive from './cardFive';
import cardSix from './cardSix';
import cardSeven from './cardSeven';
import { queryCardinfo } from '@/api/companyManage.js';

export default {
    components: {
        cardOne,
        cardTwo,
        cardThree,
        cardFour,
        cardFive,
        cardSix,
        cardSeven
    },
    data() {
        return {
            cardList: [
                {
                    type: 0,
                    name: 'cardOne'
                },
                {
                    type: 1,
                    name: 'cardTwo'
                },
                {
                    type: 4,
                    name: 'cardThree'
                },
                {
                    type: 5,
                    name: 'cardFour'
                },
                {
                    type: 6,
                    name: 'cardFive'
                },
                {
                    type: 2,
                    name: 'cardSix'
                },
                {
                    type: 3,
                    name: 'cardSeven'
                },
            ],
            cuRidx: 0,
            componentCur: 'cardOne',
            info: null,
        }
    },
    methods: {
        changeRadio(val) {
            this.cuRidx = val;
            let item = this.cardList.filter(v => v.type == val),
                name = item[0].name;
            this.componentCur = name;
            this.$emit('change',val);
        },
        getData(){
            let opts = {
                userId: localStorage.getItem('userId')
            };
            queryCardinfo(opts).then(res=>{
                if(res.code == 200){
                    this.info = res.data;
                    this.cuRidx = res.data.style;
                    let item = this.cardList.filter(v => v.type == res.data.style),
                        name = item[0].name;
                    this.componentCur = name;
                }
            })
        },
    },
    mounted() {
        this.getData();
    },
}
</script>

<style lang="scss" scoped>
.style_con {
    width: 100%;
    padding: 24px;

    .label {
        margin-right: 60px;
        font-size: 14px;
        color: #606266;

        span {
            color: #F56C6C;
        }
    }

    .cus_radio {
        width: 200px;
        flex-wrap: wrap;
    }

    .card_container {
        width: 500px;
        height: 570px;
        border: 1px dashed #E0E0E0;
        border-radius: 8px;
        margin-left: 200px;
        padding: 28px 30px;
    }
}

::v-deep {
    .el-radio {
        width: 100%;
        margin-bottom: 32px;
    }
}
</style>